define("trunk/models/organization", ["exports", "ember-data", "trunk/mixins/models/changeable", "trunk/helpers/format-cents"], function (_exports, _emberData, _changeable, _formatCents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, {
    installation: _emberData.default.belongsTo('installation', {
      async: false
    }),
    subscription: _emberData.default.belongsTo('subscription', {
      async: false
    }),
    users: _emberData.default.hasMany('user', {
      inverse: 'organizations',
      async: false
    }),
    name: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    billingEmail: _emberData.default.attr('string'),
    billedOrdersCountPerMonth: _emberData.default.attr('number'),
    pricingVersion: _emberData.default.attr('string'),
    initialVisitData: _emberData.default.attr(),
    lastVisitData: _emberData.default.attr(),
    referralCode: _emberData.default.attr('string'),
    referralCommissionCents: _emberData.default.attr('number'),
    referralCommissionCurrency: _emberData.default.attr('string'),
    referrerReferralCode: _emberData.default.attr('string'),
    // Used to contain custom data for requests
    meta: _emberData.default.attr(),
    isTrialing: Ember.computed('subscription.state', function () {
      if (this.subscription) {
        return this.subscription.isTrialing;
      }

      return false;
    }),
    referralCommissionFormatted: Ember.computed('referralCommissionCents', 'referralCommissionCurrency', function () {
      return (0, _formatCents.formatCents)([this.referralCommissionCents, this.referralCommissionCurrency], {
        style: 'simple'
      });
    }),
    referralUrl: Ember.computed('referralCode', function () {
      return "https://www.trunkinventory.com/r/".concat(this.referralCode);
    })
  });

  _exports.default = _default;
});