define("trunk/services/current-user", ["exports", "trunk/config/environment", "ember-window-mock", "urijs"], function (_exports, _environment, _emberWindowMock, _urijs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),
    window: Ember.inject.service(),
    // This needs to match the cookie key that is set on Rails since it's shared across both domains
    initialVisitDataCookieKey: '_trunk_iv',
    lastVisitDataCookieKey: '_trunk_lv',
    impersonationHistoryKey: 'impersonation-history',
    isImpersonating: Ember.computed.notEmpty('session.data.impersonation'),
    impersonation: Ember.computed.alias('session.data.impersonation'),
    isAdmin: Ember.computed('user.isAdmin', 'isImpersonating', function () {
      return this.get('user.isAdmin') || this.isImpersonating;
    }),
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.store.queryRecord('user', {
          me: true
        }, {
          include: 'organizations'
        }).then(function (user) {
          _this.set('user', user); // Set to default organization if none is currently set otherwise update currently selected organization


          if (_this.organization) {
            _this.setOrganization(_this.organization.id);
          } else {
            _this.setOrganization(user.defaultOrganization.id);
          } // Identify


          _this.analytics.identify(user.id, {
            email: user.email,
            name: user.name,
            company: {
              id: user.defaultOrganization.id,
              name: user.defaultOrganization.name
            }
          }); // Intercom


          _emberWindowMock.default.Intercom('boot', {
            api_base: _environment.default.intercom.apiBase,
            app_id: _environment.default.intercom.workspaceId,
            user_id: user.id,
            user_hash: user.intercomHash
          });

          return Ember.RSVP.resolve();
        }).catch(function (error) {});
      } else {
        return Ember.RSVP.resolve();
      }
    },
    setOrganization: function setOrganization(organizationId) {
      var organization;

      if (this.user) {
        this.user.organizations.forEach(function (loadedOrganization) {
          if (loadedOrganization.id === organizationId) {
            organization = loadedOrganization;
          }
        });
      }

      if (!organization) {
        // Manually set organization with an ID if organization is still not set
        // which could be due to no organizations having been loaded in yet or the
        // loaded organizations not containing the organization to be set (e.g.
        // force logging in another user with a different organization)
        organization = {
          id: organizationId
        };
      }

      this.set('organization', organization);
    },
    initiateUserImpersonation: function initiateUserImpersonation(userId) {
      var _this2 = this;

      // Instantiate record of user we want to impersonate if it's already in store, retrieve it otherwise create it
      // within the store
      var userToImpersonate = this.store.peekRecord('user', userId) || this.store.createRecord('user', {
        id: userId
      });
      userToImpersonate.fetchSession().then(function (response) {
        var userToken = response['data']['token'];
        var impersonation = {
          id: userId,
          token: userToken,
          authenticatedAs: _this2.user
        }; // Must be stored in data property in order to persist

        _this2.session.set('data.impersonation', impersonation);

        _this2.trackImpersonationHistory(userId, {
          name: response['data']['name'],
          defaultOrganizationName: response['data']['default-organization-name'],
          impersonatedAt: new Date()
        }); // This clears current user so that we can pull in the newly impersonated user


        _this2.reload();
      });
    },
    impersonateUser: function impersonateUser(userId) {
      var _this3 = this;

      // If already impersonating someone else, we'll need to switch by first stopping impersonation and caching the
      // impersonation user's id we need to switch to so that once we've stopped impersonating, we can then start
      // impersonating the next user (note this will all happen across some reloads)
      if (this.isImpersonating) {
        if (this.impersonation.id != userId) {
          this.set('session.data.pendingUserIdToImpersonate', userId); // For some reason we need to wait for the run loop to finish otherwise we log ourselves out (possibly because
          // we need to wait for the session to finish resolving first)

          Ember.run.next(function () {
            _this3.stopImpersonation();
          });
        }
      } else {
        this.initiateUserImpersonation(userId);
      }
    },
    stopImpersonation: function stopImpersonation() {
      this.session.set('data.impersonation', null); // Clears current user and it will automatically load back in originally authenticated user

      this.reload();
    },
    // Retrieves and camelizes data from a cookie
    retrieveVisitData: function retrieveVisitData(cookieKey) {
      if (!this.cookies.exists(cookieKey)) return {};
      var dataJson = this.cookies.read(cookieKey);
      var data = JSON.parse(dataJson);
      var camelizedData = {}; // Keys within the cookie are underscored so we need to convert to lowerCamelCase

      for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];

        camelizedData[Ember.String.camelize(key)] = value;
      } // Returns empty object if it hasn't been stored yet


      return camelizedData;
    },
    retrieveInitialVisitData: function retrieveInitialVisitData() {
      return this.retrieveVisitData(this.initialVisitDataCookieKey);
    },
    retrieveLastVisitData: function retrieveLastVisitData() {
      return this.retrieveVisitData(this.lastVisitDataCookieKey);
    },
    retrieveImpersonationHistory: function retrieveImpersonationHistory() {
      return JSON.parse(localStorage.getItem(this.impersonationHistoryKey)) || {};
    },
    trackImpersonationHistory: function trackImpersonationHistory(userId, data) {
      var impersonationHistory = this.retrieveImpersonationHistory();
      impersonationHistory[userId] = data; // Store in local storage instead of cookie since we cookie has constraints on how much data we can store in it. In
      // the future we might want to limit how far back of history we want to keep since this can grow pretty large

      localStorage.setItem(this.impersonationHistoryKey, JSON.stringify(impersonationHistory));
    },
    reset: function reset() {
      this.set('user', null);
      this.set('organization', null);
    },
    reload: function reload() {
      this.window.reload();
    },
    destroySession: function destroySession() {
      var _this4 = this;

      return this.session.invalidate(function () {
        return _this4.reset();
      });
    }
  });

  _exports.default = _default;
});